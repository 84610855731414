/*---------------------------
	Fonts
----------------------------*/

@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");

// fonts
$base-font-size: 15;
$base-font: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
  "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
  "Malgun Gothic", sans-serif;
$heading-font: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
  "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
  "Malgun Gothic", sans-serif;

// color
$dark-gray: #0a272c;
$dark-gray2: #14212b;
$body-color: #687693;
$white: #fff;
$offWhite: #e9e1e1;
$light: #cbcbcb;
$black: #000;
$small-black: #333;
$cyan: #848892;

$theme-primary-color: #9694ff;
$theme-primary-color-s2: #071e93;
$body-bg-color: #fff;
$section-bg-color: #f3f4f4;
$section-bg-color-s2: #f5f5f5;
$text-color: #6e6e6e;
$text-light-color: #676767;
$heading-color: $dark-gray;
$border-color: #ebebeb;
$border-color-s2: #d8e0f1;
