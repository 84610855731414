.hero-video-area {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  .hero-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }
  .hero-video-curtain {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0px;
    left: 0px;
  }
}

.slide-title h2 {
  font-weight: 800;
  color: #ffffff;
}

.slide-subtitle p {
  font-weight: 500;
  color: #f6f5f7;
}
