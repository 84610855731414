.wpo-section-title,
.wpo-section-title-s2 {
  margin-bottom: 75px;
  text-align: center;

  @include media-query(767px) {
    margin-bottom: 40px;
  }

  span {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 0px;
    color: $theme-primary-color;
  }

  h2 {
    font-size: 38px;
    line-height: 57px;
    margin: 0;
    margin-top: 15px;
    position: relative;
    text-transform: capitalize;
    font-family: $heading-font;
    font-weight: 700;
    margin-top: 10px;
    color: $dark-gray2;

    @include media-query(991px) {
      font-size: 25px;
      line-height: 45px;
    }
    @include media-query(767px) {
      font-size: 23px;
      line-height: 33px;
    }

    @include media-query(575px) {
      font-size: 20px;
    }
  }

  p {
    font-size: 18px;
  }
}

// ..wpo-section-title-s2

.wpo-section-title-s2 {
  h2 {
    color: $white;
  }
}
