.wpo-about-text {
  h4 {
    font-weight: 800;
    color: #9694ff !important;
  }
  h2 {
    font-weight: 800;
    color: #000000 !important;
  }
}

.hero-btn2 {
  &:hover {
    color: #5c42ff !important;
  }
}
