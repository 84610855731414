/*--------------------------------------------------------------
4. wpo-footer
--------------------------------------------------------------*/
.wpo-site-footer,
.wpo-site-footer-s2 {
  background: #1c192d;
  position: relative;
  font-size: 15px;
  overflow: hidden;
  z-index: 1;

  ul {
    list-style: none;
  }

  p {
    color: $white;
  }
  li {
    color: $white;
  }

  .container {
    position: relative;
  }

  .wpo-upper-footer {
    padding: 80px 0;
    position: relative;
    overflow: hidden;

    .ft-shape {
      position: absolute;
      right: 0;
      bottom: -10%;
      z-index: -1;

      @media (max-width: 767px) {
        bottom: -30px;
      }
    }
  }

  @media (max-width: 991px) {
    .wpo-upper-footer {
      padding: 50px 0 0;
    }
  }

  @media (max-width: 767px) {
    .wpo-upper-footer {
      padding: 60px 0 0;
    }
  }

  @media (max-width: 991px) {
    .wpo-upper-footer .col {
      min-height: 235px;
      margin-bottom: 70px;
    }
  }

  @media (max-width: 767px) {
    .wpo-upper-footer .col {
      min-height: auto;
      margin-bottom: 60px;
    }
  }

  .widget-title {
    margin-bottom: 30px;
  }

  @media (max-width: 767px) {
    .widget-title {
      margin-bottom: 20px;
    }
  }

  .widget-title {
    h3 {
      font-size: 25px;
      color: $white;
      margin: 0;
      position: relative;
      font-family: $heading-font;
      font-weight: 700;
    }
  }

  .youtubes {
    display: flex;
    .youtube {
      cursor: pointer;
      margin-bottom: 16px;
    }
    .youtube1 {
      width: 171px;
      height: 36px;
      background-image: url("../../images/youtube1.png");
      background-size: cover;
      &:hover {
        background-image: url("../../images/youtube1-hover.png");
      }
    }
    .youtube2 {
      margin-left: 16px;

      width: 122px;
      height: 36px;
      background-image: url("../../images/youtube2.png");
      background-size: cover;
      &:hover {
        background-image: url("../../images/youtube2-hover.png");
      }
    }
  }

  .about-widget {
    max-width: 350px;
    .logo {
      max-width: 180px;
    }
  }

  .about-widget {
    p {
      margin-bottom: 0.8em;
      line-height: 1.9em;
      color: $light;

      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      color: #777295;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      overflow: hidden;
      padding-top: 10px;

      li {
        font-size: 22px;
        float: left;

        a {
          color: #e7e7e7;
          width: 36px;
          height: 36px;
          line-height: 40px;
          background: rgba($color: $white, $alpha: 0.1);
          display: block;
          text-align: center;
          @include rounded-border(50%);
          font-size: 18px;

          &:hover {
            color: $white;
            background: $theme-primary-color;
          }
        }
      }

      li + li {
        margin-left: 25px;

        @media (max-width: 1200px) {
          margin-left: 15px;
        }
      }
    }
  }
  .wpo-service-link-widget {
    padding-left: 70px;
  }

  .link-widget {
    overflow: hidden;

    @media (max-width: 767px) {
      max-width: 350px;
    }

    ul {
      li {
        position: relative;
        z-index: 1;
        padding: 6px 0;
        padding-left: 15px;

        &:before {
          position: absolute;
          left: 0px;
          top: 50%;
          transform: translateY(-50%);
          content: "-";
          z-index: -1;
        }
        a {
          color: $light;
          font-size: 16px;

          &:hover {
            color: $theme-primary-color;
          }
        }
      }
    }
  }

  /*** blog-widget  ***/

  .blog-widget {
    padding-right: 30px;
    padding-left: 20px;

    @media (max-width: 1200px) {
      padding: 0;
    }
    ul {
      li {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
        h4 {
          font-size: 18px;
          line-height: 30px;

          a {
            color: $white;

            &:hover {
              color: $theme-primary-color;
            }
          }
        }
        span {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: $light;

          i {
            margin-right: 5px;
            color: $theme-primary-color;
            margin-top: -2px;
          }
        }
      }
    }
  }

  /*** newsletter-widget ***/
  .newsletter-widget {
    form {
      margin-top: 25px;
      position: relative;

      input {
        background-color: #28343e;
        height: 55px;
        color: $white;
        padding: 6px 15px;
        border: 1px solid #28343e;
        box-shadow: none;
      }

      .submit {
        position: absolute;
        right: 5px;
        top: 50%;
        @include translatingY();

        button {
          background: $theme-primary-color;
          border: 0;
          outline: 0;
          font-size: 20px;
          color: $white;
          width: 45px;
          height: 45px;
          line-height: 45px;
          border-radius: 5px;
        }
      }
    }
  }

  .wpo-lower-footer {
    text-align: center;
    position: relative;
    background: #18252f;

    .row {
      padding: 20px 0;
      position: relative;
    }

    .copyright {
      display: inline-block;
      font-size: 15px;
      font-size: 0.9375rem;
      margin: 0;

      a {
        color: $white;
        text-decoration: none;
      }

      @media (max-width: 991px) {
        float: none;
        display: block;
      }
    }
  }

  .instagram {
    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0px -3px;

      li {
        -ms-flex: 0 0 33.33%;
        flex: 0 0 33.33%;
        max-width: 33.33%;
        margin-bottom: 5px;
        padding: 0px 3px;

        img {
          width: 100%;
        }
      }
    }
  }
}

// .wpo-site-footer-s2

.wpo-site-footer-s2 {
  .link-widget {
    padding-left: 50px;

    @media (max-width: 1200px) {
      padding: 0;
    }
  }

  .ft-shape-s1 {
    position: absolute;
    left: -30px;
    top: -50px;
    z-index: -1;

    svg {
      fill: $theme-primary-color;
    }
  }
  .ft-shape-s2 {
    position: absolute;
    right: -60px;
    bottom: -70px;
    z-index: -1;

    svg {
      fill: $theme-primary-color;
    }
  }

  .ft-shape-s3 {
    position: absolute;
    bottom: 0;
    right: 15%;
    z-index: -1;

    @media (max-width: 1600px) {
      opacity: 0.5;
    }
  }

  .wpo-lower-footer {
    text-align: left;

    @media (max-width: 991px) {
      text-align: center;
    }
    ul {
      li {
        a {
          color: $theme-primary-color;
        }
      }
    }
    .link {
      ul {
        display: flex;
        justify-content: flex-end;

        @media (max-width: 991px) {
          justify-content: center;
          margin-top: 10px;
          flex-wrap: wrap;
        }

        li {
          a {
            color: $white;

            &:hover {
              color: $theme-primary-color;
            }
          }
          & + li {
            padding-left: 20px;
            margin-left: 10px;
            position: relative;

            @media (max-width: 375px) {
              padding-left: 5px;
              margin-left: 5px;
            }

            &::after {
              position: absolute;
              left: 0;
              top: 0;
              content: "//";

              @media (max-width: 375px) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
